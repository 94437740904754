import $ from 'jquery'

var simpletabspluginname = 'simpletabs'
function SimpleTabsPlugin(element, options) {
  this.$el = $(element)
  this.$tabs = []
  this.init()
}

$.extend(SimpleTabsPlugin.prototype, {
  init() {
    var self = this
    this.$tabs = []
    this.$el.find('ul > li > a').each(function() {
      var $a = $(this)
      var id = $a.attr('href')
      if ( id.startsWith('#') ) {
        id = id.replace('#', '')
        var exists = self.$tabs.find(function(element) {
          return element === id
        })
        var contentselector = '#' + id + '.tabs-content'
        var content = self.$el.find(contentselector)
        if ( exists ) {
          exists.tab = $a
          exists.content = content
        } else {
          self.$tabs.push({
            id: id,
            tab: $a,
            content: content
          })
        }
      }
    })
    self.$tabs.forEach(function(element) {
      if (!element.tab.hasClass('active')) {
        self.hide(element.id)
      } else {
        self.show(element.id)
      }

      element.tab.click(function(event) {
        event.preventDefault()
        self.show(element.id)
      })
    })
  },
  show(id) {
    var self = this
    this.$tabs.forEach(function( element ) {
      self.hide(element.id)
      if ( element.id === id ) {
        element.tab.addClass('active').addClass('font-bold')
        element.content.removeClass('hidden')
      }
    })
  },
  hide(id) {
    this.$tabs.forEach(function( element ) {
      if ( element.id === id ) {
        element.tab.removeClass('active').removeClass('font-bold')
        element.content.addClass('hidden')
      }
    })
  },
})

$.fn.simpletabs = function(options) {
  this.each(function() {
    if ( !$.data(this, 'plugin_' + simpletabspluginname)) {
      $.data(this, 'plugin_' + simpletabspluginname, new SimpleTabsPlugin(this, options))
    }
  })
}


